import { useEffect, useState } from "react"
import { useCurrentWidth } from "./news-utils"

export const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false)
  let width = useCurrentWidth()
  useEffect(() => {
    if (!width) {
      return
    }
    if (width > 812) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, width)
  return isMobile
}
