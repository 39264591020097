import { Link } from "gatsby"
import React, { Component } from "react"
import bwlogo from "../../../../assets/IronCAP logo black (480 x 110).png"
import facebook from "../../../../assets/Facebook (circle).png"
import twitter from "../../../../assets/Twitter (circle).png"
import youtube from "../../../../assets/YouTube (circle).png"
import linkedin from "../../../../assets/linkedin-logo.png"
import blog from "../../../../assets/Blog (circle).png"
import * as styles from "./footer.module.scss"
import { Location } from "@reach/router"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { TM } from "../typography/trademark"

class Footer extends Component {
  constructor() {
    super()
    this.state = {
      isDesktopOrTablet: true,
    }
  }

  componentDidMount() {
    this.updateViewport()
    window.addEventListener("resize", this.updateViewport)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewport)
  }

  updateViewport = () => {
    this.setState({ isDesktopOrTablet: window.innerWidth > 812 })
  }

  render() {
    return (
      <div className={styles.footer}>
        <div className={styles.wrapper}>
          <div className={styles.footerMenu}>
            <img src={bwlogo} className={styles.bwlogo} alt="IronCAP logo" />

            <Location>
              {({ location }) => {
                if (location.pathname.includes("ironcap-x")) {
                  // IRONCAP X FOOTER
                  return (
                    <ul className={styles.footerNav}>
                      {/* TODO: grab names from GraphQL? */}
                      <li>
                        <Link to="/ironcap-x/about">
                          About IronCAP X<TM />
                        </Link>
                      </li>
                      <li>
                        <Link to="/news">News</Link>
                      </li>
                      <li>
                        <Link to="/ironcap-x/support">Support</Link>
                      </li>
                      <li>
                        <OutboundLink
                          href="https://www.01com.com/pdf/ironcapx-privacy-policy.pdf"
                          target="_blank"
                        >
                          Privacy Policy
                        </OutboundLink>
                      </li>
                      <li>
                        <OutboundLink
                          href="https://www.01com.com/pdf/ironcapx-terms-of-service.pdf"
                          target="_blank"
                        >
                          Terms of Service
                        </OutboundLink>
                      </li>
                      {this.state.isDesktopOrTablet ? null : (
                        <li>
                          <Link to="/contact-us/">Contact Us</Link>
                        </li>
                      )}
                    </ul>
                  )
                } else if (location.pathname.includes("ironcap-toolkits")) {
                  // IronCAP Toolkits FOOTER
                  return (
                    <ul className={styles.footerNav}>
                      <li>
                        <Link to="/ironcap-toolkits/about">
                          About IronCAP
                          <TM /> Toolkits
                        </Link>
                      </li>
                      <li>
                        <OutboundLink
                          href="https://www.01com.com/pdf/ironcap-white-paper.pdf"
                          target="_blank"
                        >
                          White Paper
                        </OutboundLink>
                      </li>
                      <li>
                        <Link to="/news">News</Link>
                      </li>
                      <li>
                        <Link to="/ironcap-toolkits/support">Support</Link>
                      </li>
                      {this.state.isDesktopOrTablet ? null : (
                        <li>
                          <Link to="/contact-us/">Contact Us</Link>
                        </li>
                      )}
                    </ul>
                  )
                } else {
                  // MAIN FOOTER
                  return (
                    <ul className={styles.footerNav}>
                      <li>
                        <Link to="/about-us">About Us </Link>
                      </li>
                      <li>
                        <Link to="/ironcap-x">
                          IronCAP X<TM />
                        </Link>
                      </li>
                      <li>
                        <Link to="/ironcap-toolkits">
                          IronCAP
                          <TM /> Toolkits
                        </Link>
                      </li>
                      <li>
                        <Link to="/news">News</Link>
                      </li>
                      <li>
                        <Link to="/support">Support </Link>
                      </li>
                      {this.state.isDesktopOrTablet ? null : (
                        <li>
                          <Link to="/contact-us/">Contact Us</Link>
                        </li>
                      )}
                    </ul>
                  )
                }
              }}
            </Location>
            {this.state.isDesktopOrTablet ? (
              <p className={styles.contact}>
                <Link to="/contact-us/">Contact Us</Link>
              </p>
            ) : null}
          </div>
          <div className={styles.socialContainer}>
            <ul>
              {/* TODO: grab names from GraphQL */}
              <li>
                <OutboundLink
                  href="https://www.facebook.com/IronCAP01/"
                  target="_blank"
                >
                  <img
                    src={facebook}
                    className={styles.socialIcon}
                    alt="facebook"
                  />
                </OutboundLink>
              </li>
              <li>
                <OutboundLink
                  href="https://twitter.com/IronCAPCyber"
                  target="_blank"
                >
                  <img
                    src={twitter}
                    className={styles.socialIcon}
                    alt="twitter"
                  />
                </OutboundLink>
              </li>
              <li>
                <OutboundLink
                  href="https://www.youtube.com/channel/UCrbGgkSemPtfQgpKX8stySg/"
                  target="_blank"
                >
                  <img
                    src={youtube}
                    className={styles.socialIcon}
                    alt="youtube"
                  />
                </OutboundLink>
              </li>
              <li>
                <OutboundLink
                  href="https://www.linkedin.com/company/ironcap/"
                  target="_blank"
                >
                  <img
                    src={linkedin}
                    className={styles.socialIcon}
                    alt="linkedIn"
                  />
                </OutboundLink>
              </li>
              <li>
                <OutboundLink href="http://blog.01com.com/wp/" target="_blank">
                  <img src={blog} className={styles.socialIcon} alt="blog" />
                </OutboundLink>
              </li>
            </ul>
          </div>
          <div className={styles.disclaimer}>
            <p>
              Copyright © 2018-{new Date().getFullYear()}{" "}
              <OutboundLink href="https://01com.com" target="_blank">
                01 Communique
              </OutboundLink>
              . All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    )
  }
}
export default Footer
