import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import logo from "../../../../assets/cgilogo.png"

import * as styles from "./cgiheader.module.scss"
class CGIHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hamburgerOpen: false,
      isDesktopOrTablet: true,
    }
  }

  updateViewport = () => {
    this.setState({ isDesktopOrTablet: window.innerWidth > 812 })
  }

  componentDidMount() {
    this.updateViewport()
    window.addEventListener("resize", this.updateViewport)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewport)
  }

  handleClick = id => {
    let { hamburgerOpen } = this.state
    this.setState({
      hamburgerOpen: !hamburgerOpen,
    })
  }

  logout(e) {
    let btn1 = document.getElementById("liveDemoDesktopBtn")
    btn1.innerHTML = btn1.innerHTML = "Live Demo"
    let logout = window.document.getElementById("btnLogout")

    if (logout) {
      logout.click()
    }
  }

  showBusinessPersonalDesktopButton() {
    return (
      <div className={styles.buttonContainer}>
        <Link to="/ironcap-x/personal">
          <button className={styles.buttonFree}>FREE Personal Usage</button>
        </Link>
        {/* 
	        <Link to="/ironcap-x/business">
	          <button className={styles.buttonBusiness}>Business Usage</button>
	        </Link> */}
      </div>
    )
  }

  showBusinessPersonalSmallButton() {
    return (
      <div className={styles.buttonContainer}>
        <Link to="/ironcap-x/personal">
          <button className={styles.buttonFreeMobile}>
            FREE Personal Usage
          </button>
        </Link>
        {/* <Link to="/ironcap-x/business">
          <button className={styles.buttonBusiness}>Business Usage</button>
        </Link> */}
      </div>
    )
  }

  showDemoDesktopButton() {
    return (
      <div className={styles.buttonContainer}>
        <Link to="/demo">
          <button
            id="liveDemoDesktopBtn"
            className={styles.buttonFree}
            onClick={this.logout}
          >
            Live Demo
          </button>
        </Link>
      </div>
    )
  }

  showDemoSmallButton() {
    return (
      <div className={styles.buttonContainer}>
        <Link to="/demo" onClick="return false;">
          <button id="liveDemoSmallBtn" className={styles.buttonFreeMobile}>
            Live Demo
          </button>
        </Link>
      </div>
    )
  }

  render() {
    return (
      <header className={styles.header}>
        <div className={styles.wrapper}>
          <div className={styles.headerContainer}>
            <img src={logo} className={styles.logo} alt="IronCAP logo" />
          </div>
        </div>
      </header>
    )
  }
}

CGIHeader.propTypes = {
  siteTitle: PropTypes.string,
}

CGIHeader.defaultProps = {
  siteTitle: `IronCAP`,
}

export default CGIHeader
