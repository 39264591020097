/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "../components/common/header/header.js"
import CGIHeader from "../components/common/header/cgiheader.js"

import "./layout.css"
import Footer from "./common/footer/footer.js"
import { Location } from "@reach/router"

const Layout = ({ children, hideHeader = false, hideFooter = false }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Location>
          {({ location }) => {
            if (location.pathname.includes("demo/cgi")) {
              return <CGIHeader siteTitle={data.site.siteMetadata.title} />
            } else {
              return (
                !hideHeader && (
                  <Header siteTitle={data.site.siteMetadata.title} />
                )
              )
            }
          }}
        </Location>

        <div
          style={{
            margin: `0 auto`,
            maxWidth: "86%",
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          {!hideFooter && (
            <footer>
              {/* © {new Date().getFullYear()}, Built with
            {` `}

            <a href="https://www.gatsbyjs.org">Gatsby</a> */}
              <Footer />
            </footer>
          )}
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
