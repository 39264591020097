import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import logo from "../../../../assets/IronCAP logo white (480 x 110).png"
import bountyContestImage from "../../../../assets/bountycontestbtn.png"
import bountyContestImageCT from "../../../../assets/bountycontestctbtn.png"
import "react-modal-video/scss/modal-video.scss"
import ModalVideo from "react-modal-video"

import * as styles from "./header.module.scss"
import { Location } from "@reach/router"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import axios from "axios"

import HamburgerMenu from "react-hamburger-menu"
import { Dropdown } from "./menu-dropdown"
import { TM } from "../typography/trademark"
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hamburgerOpen: false,
      isDesktopOrTablet: true,
      daysLeft: "",
      appState: "expired",
      showDropdown: false,
      modalOpen: false,
      isBrowser: false,
    }
  }

  updateViewport = () => {
    this.setState({ isDesktopOrTablet: window.innerWidth > 812 })
  }

  componentDidMount() {
    this.updateViewport()
    window.addEventListener("resize", this.updateViewport)
    this.getContestInfo()
    if (typeof window !== `undefined`) {
      this.setState({
        isBrowser: true,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewport)
  }

  handleClick = id => {
    let { hamburgerOpen } = this.state
    this.setState({
      hamburgerOpen: !hamburgerOpen,
    })
  }

  getApiURL(param) {
    return `${process.env.DEMO_SERVER_API_URL}/${param}`
  }

  getContestInfo() {
    let self = this

    const u = typeof window !== "undefined" ? window.location.href : ""
    let lang = u.includes("ironcap-bountycontest/ct") ? "ct" : "en"

    let url = this.getApiURL("deadline")

    axios
      .get(url, { params: { lang: lang } })
      .then(function(response) {
        self.setState({
          daysLeft: response.data.diffDays,
          appState: response.data.state,
        })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  logout(e) {
    let btn1 = document.getElementById("liveDemoDesktopBtn")
    // let btn2 = document.getElementById("liveDemoSmallBtn")
    btn1.innerHTML = btn1.innerHTML = "Live Demo"
    let logout = window.document.getElementById("btnLogout")

    if (logout) {
      logout.click()
    }
  }

  showBusinessPersonalDesktopButton() {
    return (
      <div className={styles.buttonContainer}>
        <Link to="/ironcap-x/personal">
          <button className={styles.buttonFree}>FREE Personal Usage</button>
        </Link>
        {/* 
	        <Link to="/ironcap-x/business">
	          <button className={styles.buttonBusiness}>Business Usage</button>
	        </Link> */}
      </div>
    )
  }

  showBusinessPersonalSmallButton() {
    return (
      <div className={styles.buttonContainer}>
        <Link to="/ironcap-x/personal">
          <button className={styles.buttonFreeMobile}>
            FREE Personal Usage
          </button>
        </Link>
        {/* <Link to="/ironcap-x/business">
          <button className={styles.buttonBusiness}>Business Usage</button>
        </Link> */}
      </div>
    )
  }

  showBountyContestButton() {
    if (
      this.state.appState === "closed" ||
      this.state.appState === "pre" ||
      this.state.appState === "expired"
    ) {
      return (
        <div className={styles.buttonBountyContestContainer}>
          <button className={styles.buttonBountyContest}>
            <img
              src={bountyContestImage}
              className={styles.imageBountyContest}
              alt="bounty contest button image"
            />
            {this.state.daysLeft}
          </button>
        </div>
      )
    }

    if (
      this.state.appState === "registration" ||
      this.state.appState === "contest"
    ) {
      return (
        <div className={styles.buttonBountyContestContainer}>
          <Link to="/ironcap-bountycontest/en">
            <button className={styles.buttonBountyContest}>
              <Location>
                {({ location }) => {
                  if (location.pathname.includes("ironcap-bountycontest/ct")) {
                    return (
                      <img
                        src={bountyContestImageCT}
                        className={styles.imageBountyContest}
                        alt="bounty contest button image"
                      />
                    )
                  } else {
                    return (
                      <img
                        src={bountyContestImage}
                        className={styles.imageBountyContest}
                        alt="bounty contest button image"
                      />
                    )
                  }
                }}
              </Location>
              {this.state.daysLeft}
            </button>
          </Link>

          <Location>
            {({ location }) => {
              if (location.pathname.includes("ironcap-bountycontest/ct")) {
                return (
                  <div className={styles.languages}>
                    <Link to="ironcap-bountycontest/en">English</Link>|{" "}
                    <Link
                      to="ironcap-bountycontest/ct"
                      className={styles.activeLanguage}
                    >
                      中文
                    </Link>
                  </div>
                )
              } else if (
                location.pathname.includes("ironcap-bountycontest/en")
              ) {
                return (
                  <div className={styles.languages}>
                    <Link
                      to="ironcap-bountycontest/en"
                      className={styles.activeLanguage}
                    >
                      English
                    </Link>
                    | <Link to="ironcap-bountycontest/ct">中文</Link>
                  </div>
                )
              } else {
                return <React.Fragment />
              }
            }}
          </Location>
        </div>
      )
    }
  }

  showBountyContestSmallButton() {
    return (
      <div className={styles.buttonBountyContestContainer}>
        <button className={styles.buttonBountyContest}>
          <Location>
            {({ location }) => {
              if (location.pathname.includes("ironcap-bountycontest/ct")) {
                return (
                  <img
                    src={bountyContestImageCT}
                    className={styles.imageBountyContest}
                    alt="bounty contest button image"
                  />
                )
              } else {
                return (
                  <img
                    src={bountyContestImage}
                    className={styles.imageBountyContest}
                    alt="bounty contest button image"
                  />
                )
              }
            }}
          </Location>
          {this.state.daysLeft}
        </button>
      </div>
    )
  }

  showDemoDesktopButton() {
    return (
      <div className={styles.buttonContainer}>
        <Link to="/demo">
          <button
            id="liveDemoDesktopBtn"
            className={styles.buttonFree}
            onClick={this.logout}
          >
            Live Demo
          </button>
        </Link>
      </div>
    )
  }

  showDemoSmallButton() {
    return (
      <div className={styles.buttonContainer}>
        <Link to="/demo" onClick="return false;">
          <button id="liveDemoSmallBtn" className={styles.buttonFreeMobile}>
            Live Demo
          </button>
        </Link>
      </div>
    )
  }

  getResourcesDropdownItems(currentLocation) {
    return [
      {
        name: "Brochures",
        subMenu: [
          {
            name: (
              <>
                IronCAP
                <TM />
              </>
            ),
            page: null,
            url: `https://01com.com/pdf/brochures/IronCAP Brochure Digital.pdf`,
          },
          {
            name: (
              <>
                IronCAP X
                <TM />
              </>
            ),
            page: null,
            url: `https://01com.com/pdf/brochures/IronCAP X Brochure_Web.pdf`,
          },
        ],
      },
      {
        name: "Support",
        subMenu: null,
        page: `${currentLocation}/support`,
        url: null,
      },
      {
        name: "White Papers & Use Cases",
        subMenu: null,
        page: `${currentLocation}/white-papers-use-cases`,
        url: null,
      },
      {
        name: "Videos",
        subMenu: null,
        page: `${currentLocation}/videos`,
      },
    ]
  }

  render() {
    return (
      <header className={styles.header}>
        <div className={styles.wrapper}>
          <div className={styles.headerContainer}>
            <Link to="/" className={styles.logoContainer}>
              <img src={logo} className={styles.logo} alt="IronCAP logo" />
            </Link>
            {this.state.isDesktopOrTablet ? null : (
              <HamburgerMenu
                isOpen={this.state.hamburgerOpen}
                menuClicked={this.handleClick}
                width={26}
                height={20}
                strokeWidth={2}
                rotate={0}
                color="white"
                borderRadius={0}
                animationDuration={0.5}
                className={styles.hamburger}
              />
            )}
            <Location>
              {({ location }) => {
                if (location.pathname.includes("ironcap-x")) {
                  if (
                    this.state.isDesktopOrTablet ||
                    (!this.state.isDesktopOrTablet && this.state.hamburgerOpen)
                  ) {
                    return (
                      <ul className={styles.nav}>
                        {/* TODO: grab names from GraphQL? */}
                        <li>
                          <Link to="/ironcap-x/about/">
                            About IronCAP X<TM />
                          </Link>
                        </li>
                        <li>
                          <Link to="/news/">News</Link>
                        </li>
                        <li onClick={() => this.setState({ modalOpen: true })}>
                          How It Works
                        </li>
                        <Dropdown
                          title="Resources"
                          menuItems={this.getResourcesDropdownItems(
                            "/ironcap-x"
                          )}
                        />
                      </ul>
                    )
                  }
                } else if (location.pathname.includes("ironcap-toolkits")) {
                  if (
                    this.state.isDesktopOrTablet ||
                    (!this.state.isDesktopOrTablet && this.state.hamburgerOpen)
                  ) {
                    return (
                      <ul className={styles.nav}>
                        <li>
                          <Link to="/ironcap-toolkits/about">
                            About IronCAP
                            <TM /> Toolkits
                          </Link>
                        </li>
                        <li>
                          <OutboundLink
                            href="https://www.01com.com/pdf/ironcap-white-paper.pdf"
                            target="_blank"
                          >
                            White Paper
                          </OutboundLink>
                        </li>
                        <li>
                          <Link to="/news/">News</Link>
                        </li>
                        <Dropdown
                          title="Resources"
                          menuItems={this.getResourcesDropdownItems(
                            "/ironcap-toolkits"
                          )}
                        />
                      </ul>
                    )
                  }
                } else if (location.pathname.includes("cgidemo")) {
                  return <React.Fragment />
                } else if (location.pathname.includes("demo")) {
                  return <React.Fragment />
                } else if (
                  location.pathname.includes("ironcap-bountycontest")
                ) {
                  return <React.Fragment />
                } else {
                  if (
                    this.state.isDesktopOrTablet ||
                    (!this.state.isDesktopOrTablet && this.state.hamburgerOpen)
                  ) {
                    return (
                      <ul className={styles.nav}>
                        <li>
                          <Link to="/about-us/">About Us</Link>
                        </li>
                        <li>
                          <Link to="/ironcap-x/">
                            IronCAP X<TM />
                          </Link>
                        </li>
                        <li>
                          <Link to="/ironcap-toolkits/">
                            IronCAP
                            <TM /> Toolkits
                          </Link>
                        </li>
                        <li>
                          <Link to="/news/">News</Link>
                        </li>
                        <li>
                          <Link to="/partners/">Partners</Link>
                        </li>
                        <Dropdown
                          title="Resources"
                          menuItems={this.getResourcesDropdownItems("")}
                        />
                      </ul>
                    )
                  }
                }
              }}
            </Location>
            <Location>
              {({ location }) => {
                if (this.state.isDesktopOrTablet) {
                  if (location.pathname.includes("ironcap-x")) {
                    return this.showBusinessPersonalDesktopButton()
                  } else if (location.pathname.includes("cgidemo")) {
                    return <React.Fragment />
                  } else if (
                    location.pathname.includes("ironcap-bountycontest")
                  ) {
                    return this.showBountyContestButton()
                  } else {
                    if (
                      this.state.appState === "registration" ||
                      this.state.appState === "contest" ||
                      this.state.appState === "closed"
                    ) {
                      return this.showBountyContestButton()
                    }
                    return this.showDemoDesktopButton()
                  }
                }
              }}
            </Location>
          </div>

          <Location>
            {({ location }) => {
              if (!this.state.isDesktopOrTablet) {
                if (location.pathname.includes("ironcap-x")) {
                  return this.showBusinessPersonalSmallButton()
                } else if (
                  location.pathname.includes("ironcap-bountycontest")
                ) {
                  return this.showBountyContestSmallButton()
                } else {
                  if (
                    this.state.appState === "registration" ||
                    this.state.appState === "contest" ||
                    this.state.appState === "closed"
                  ) {
                    return this.showBountyContestButton()
                  }

                  return this.showDemoSmallButton()
                }
              }
            }}
          </Location>
          {this.state.isBrowser && (
            <ModalVideo
              channel="youtube"
              isOpen={this.state.modalOpen}
              videoId="iKSD8MYShsY"
              onClose={() => this.setState({ modalOpen: false })}
              style={{ position: "absolute" }}
            />
          )}
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `IronCAP`,
}

export default Header
