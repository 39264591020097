import { useState, useEffect } from "react"
import { importAll } from "./image-utils"

const images = importAll(
  require.context("../../assets/news", false, /\.(png|jpe?g|svg)$/)
)

export const getNewsPairings = data => {
  let pairs = { first: {}, second: null }

  return data.reduce((acc, next, i) => {
    if (i % 2 === 0) {
      pairs.first = {
        ...data[i],
        image: images[data[i].img],
        imageMin: images[data[i].imgmin],
      }

      return i === data.length - 1 ? acc.concat(pairs) : acc
    } else {
      pairs.second = {
        ...data[i],
        image: images[data[i].img],
        imageMin: images[data[i].imgmin],
      }
      const result = acc.concat(pairs)
      pairs = { first: {}, second: null }
      return result
    }
  }, [])
}

export const getAnnouncementNews = data => {
  const announcements = data.filter(news => news.tag === "Announcements")
  return getNewsPairings(announcements)
}

export const getAwardsNews = data => {
  const awards = data.filter(news => news.tag === "Awards")
  return getNewsPairings(awards)
}

export function useCurrentWidth() {
  const [windowWidth, setWindowWidth] = useState()

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null

    function handleResize() {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => setWindowWidth(window.innerWidth), 150)
    }
    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowWidth
}
