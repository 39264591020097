import { Link } from "gatsby"
import React from "react"
import { useMobile } from "../../../utils/use-mobile"
import * as styles from "./header.module.scss"

export const Dropdown = ({ title, menuItems, subMenu }) => {
  const isMobile = useMobile()

  const getMenuLink = menuItem => {
    if (menuItem.page) {
      return <Link to={menuItem.page}>{menuItem.name}</Link>
    } else {
      return (
        <a href={menuItem.url} target="_blank">
          {menuItem.name}
        </a>
      )
    }
  }
  const getMenuItem = (menuItem, index) => {
    let menuItemName = menuItem.name

    if (menuItem.subMenu && menuItem.subMenu.length > 0) {
      return (
        <li>
          {menuItemName}
          <Dropdown menuItems={menuItem.subMenu} subMenu={true} />
        </li>
      )
    } else {
      return <li>{getMenuLink(menuItem)}</li>
    }

    return <li key={menuItem.name}>{getMenuLink(menuItem)}</li>
  }

  let options = []

  menuItems.map((item, index) => {
    options.push(getMenuItem(item, index))
  })

  if (subMenu && subMenu === true) return <ul>{options}</ul>

  return isMobile ? (
    <>{options}</>
  ) : (
    <li className={styles.dropdownContainer}>
      {title}
      <ul className={styles.dropdown}>{options}</ul>
    </li>
  )
}
